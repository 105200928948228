/**
 * @description check if Image for Background load
 * @param imageSrc image to check
 * @param onloadFallback fallback onload
 * @param onerrorFallback fallback onerror
 */
export function checkImage(imageSrc: string, onloadFallback: () => void, onerrorFallback: () => void): void {
  const img = new Image();
  img.onload = onloadFallback;
  img.onerror = onerrorFallback;
  img.src = imageSrc;
}

/**
 * Converts a JavaScript Date object to a timestamp in seconds.
 * @param date - The Date object to be converted.
 * @returns A number representing the timestamp in seconds, or undefined if the input date is invalid or not provided.
 */
export function dateToTimestampInS(date?: Date): number | undefined {
  if (date !== undefined && date !== null && !isNaN(date.getTime())) {
    return Math.floor(date.getTime() / 1000); // Divisé par 1000 pour obtenir un timestamp en secondes
  } else {
    return undefined;
  }
}
